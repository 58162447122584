import { Box } from "@mui/material";
import type { PimoReactComponent } from "@pimo/pimo-app-builder";
import { TitleCard } from "@pimo/pimo-components";
import {
  ProjectOverviewTitleCardSearchBarSlot,
  ProjectOverviewTitleCardSearchBarSlotProps,
} from "./project-overview-title-card-search-bar-slot";
import { FilterData } from "crq-types";

export type ProjectOverviewCardProps = {
  filterData: FilterData;
  title: string;
};

export const ProjectOverviewTitleCard: PimoReactComponent<
  ProjectOverviewCardProps,
  "filter:clear" | "filter:apply",
  FilterData
> = ({ filterData, fireEvent, title, ...props }) => {
  return (
    <TitleCard
      title={title}
      endSlot={
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <ProjectOverviewTitleCardSearchBarSlot
            {...(props as ProjectOverviewTitleCardSearchBarSlotProps)}
            clearAllFilters={() => fireEvent?.("filter:clear")}
            filterData={filterData}
            handleFilterChange={(event, trigger) => {
              const key = trigger as keyof Pick<
                FilterData,
                "names" | "regions" | "status"
              >;
              const value = event.target.name;
              const checked = event.target.checked;

              fireEvent?.("filter:apply", {
                ...filterData,
                [key]: checked
                  ? [...filterData[key], value]
                  : filterData[key].filter((v) => v !== value),
              });
            }}
            handleSearchQueryChange={(value) => {
              fireEvent?.("filter:apply", { ...filterData, search: value });
            }}
          />
        </Box>
      }
    />
  );
};
