import type { QuestionnaireResponse } from "crq-types";
import type { FieldError, Path } from "react-hook-form";

export type FormFieldErrors = {
  [key in Path<QuestionnaireResponse>]?: FieldError;
};

const MIN_STORED_RECORDS_ERROR = {
  type: "required",
  message: "This field's value must be more than 0.",
};

const MAX_STORED_RECORDS_ERROR = {
  type: "required",
  message:
    "This field's value cannot be bigger than the sum of PII, PCI and PHI records.",
};

export function validateFormData(data: QuestionnaireResponse): FormFieldErrors {
  const errors: FormFieldErrors = {};

  if (!data) {
    return errors;
  }

  if (
    !+data.maxRecordsOnPremise &&
    +data.nrPIIOnPremise + +data.nrPCIOnPremise + +data.nrPHIOnPremise > 0
  ) {
    errors.maxRecordsOnPremise = MIN_STORED_RECORDS_ERROR;
  }

  if (
    +data.maxRecordsOnPremise >
    +data.nrPIIOnPremise + +data.nrPCIOnPremise + +data.nrPHIOnPremise
  ) {
    errors.maxRecordsOnPremise = MAX_STORED_RECORDS_ERROR;
  }

  if (
    !+data.maxRecordsCloud &&
    +data.nrPIICloud + +data.nrPCICloud + +data.nrPHICloud > 0
  ) {
    errors.maxRecordsCloud = MIN_STORED_RECORDS_ERROR;
  }

  if (
    +data.maxRecordsCloud >
    +data.nrPIICloud + +data.nrPCICloud + +data.nrPHICloud
  ) {
    errors.maxRecordsCloud = MAX_STORED_RECORDS_ERROR;
  }

  return errors;
}
