import { Box } from "@mui/material";
import {
  ChartsLegendPropsBase,
  LegendRendererProps,
  LineChart,
} from "@mui/x-charts";
import {
  LegendPerItem,
  LegendPerItemProps,
} from "@mui/x-charts/ChartsLegend/LegendPerItem";
import type {
  LineChartProps,
  LineElementSlotProps,
} from "@mui/x-charts/LineChart";
import type { PimoReactComponent } from "@pimo/pimo-app-builder";
import type { PropsWithChildren } from "react";

import { Card, CardContent, type CardProps } from "../../molecules";
import { ForecastLine } from "./forecast-line";

export type MuiLineChartCardProps = Omit<LineChartProps, "series"> & {
  /** additional card props */
  cardProps?: CardProps;
  /** card title */
  title: string;
  forecastLimit?: number;
  series: {
    /** some id to differentiate the series */
    id: string;
    /** series label */
    label: string;
    /** series values */
    data: (number | null)[];
    /** the series' color */
    color?: string;
    /** creates a line even though no value is available */
    connectNulls?: boolean;
    /** curve type to be displayed */
    curve?: "linear" | "natural";
    /** shows the mark */
    showMark?: (params: { index: number }) => boolean;
  }[];
  legendItems?: LegendPerItemProps["itemsToDisplay"];
};

export const MuiLineChartCard: PimoReactComponent<
  PropsWithChildren<MuiLineChartCardProps>
> = ({
  cardProps = {},
  children,
  title,
  series,
  forecastLimit,
  legendItems,
  ...props
}) => {
  const legend = (legendProps: ChartsLegendPropsBase) => {
    const itemsToDisplay =
      legendItems ??
      series.map(({ color, id, label }) => ({
        color: color ?? "",
        id,
        label,
      }));
    return (
      <LegendPerItem
        {...(legendProps as LegendRendererProps)}
        itemsToDisplay={itemsToDisplay}
      />
    );
  };

  return (
    <Card {...cardProps} data-testid="DonutChart" title={title}>
      <CardContent>
        {children}
        <Box>
          <LineChart
            {...props}
            height={350}
            margin={{ bottom: 100, top: -10 }}
            series={series}
            slots={{ line: ForecastLine, legend }}
            slotProps={{
              line:
                forecastLimit !== undefined
                  ? ({
                      limit: forecastLimit,
                      sxAfter: { strokeDasharray: "10 5" },
                    } as LineElementSlotProps["line"])
                  : undefined,
              legend: {
                position: { vertical: "bottom", horizontal: "right" },
              },
            }}
          />
        </Box>
      </CardContent>
    </Card>
  );
};

export default MuiLineChartCard;

export const MONTHS = [
  "January",
  "Febuary",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const QUARTERS = ["Q1", "Q2", "Q3", "Q4"];

export function getAllYearsBetweenDates(
  startYear: number,
  endYear: number
): number[] {
  const years: number[] = [];

  while (startYear <= endYear) {
    years.push(startYear);
    startYear++;
  }

  return years;
}
