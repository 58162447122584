import { Autocomplete, Chip, Grid, TextField } from "@mui/material";
import { type FocusEventHandler, useState } from "react";

export function MultiTextFormFieldControlledComponent({
  disabled,
  error,
  onBlur,
  onChange,
  name,
  value,
  items,
  showMoreIndicator = true,
}: {
  disabled?: boolean;
  error?: boolean;
  max?: number;
  onBlur: FocusEventHandler<HTMLInputElement>;
  onChange: (event: { target: { name: string; value: string[] } }) => void;
  name: string;
  value?: string[];
  items?: { label: string; value: string }[];
  showMoreIndicator?: boolean; // Optional prop for showing "+ n more" (applied only when items are provided)
}) {
  const [inputValue, setInputValue] = useState("");
  const [values, setValues] = useState(value ?? []);

  if (!Array.isArray(values)) {
    throw new Error(
      `A non-array value was handed over to MultiTextFormField for ${name}.`
    );
  }

  function handleValueChanges(newValues: string[]) {
    const validValues = newValues.filter((val) => val.trim() !== "");
    setValues(validValues);
    onChange({
      target: {
        name: name,
        value: validValues,
      },
    });
  }

  function addValue(value: string) {
    if (value.trim() !== "") {
      const newValues = [...values, value];
      handleValueChanges(newValues);
      setInputValue("");
    }
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Autocomplete
          multiple
          freeSolo={!items?.length}
          options={items?.map((item) => item.value) || []}
          value={values}
          inputValue={inputValue}
          onInputChange={(_, newInputValue) => {
            setInputValue(newInputValue);
          }}
          onChange={(_, newValue) => {
            handleValueChanges(newValue as string[]);
          }}
          renderTags={(value, getTagProps) => {
            const displayLimit = 3;
            const displayedValues =
              showMoreIndicator && items ? value.slice(0, displayLimit) : value;
            const moreCount = value.length - displayLimit;

            return [
              ...displayedValues.map((option, index) => {
                const { key, ...props } = getTagProps({ index });

                return (
                  <Chip
                    key={key}
                    variant="outlined"
                    label={
                      items?.find((item) => item.value === option)?.label ||
                      option
                    }
                    {...props}
                  />
                );
              }),
              showMoreIndicator && items && moreCount > 0 && (
                <Chip
                  key="more"
                  variant="outlined"
                  label={`+ ${moreCount} more`}
                />
              ),
            ];
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              error={error}
              fullWidth
              onBlur={onBlur}
              disabled={disabled}
              variant="outlined"
              placeholder="Type and press enter"
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                  const trimmedValue = inputValue.trim();
                  if (
                    trimmedValue &&
                    (!items?.length ||
                      items.find((item) => item.value === trimmedValue))
                  ) {
                    addValue(trimmedValue);
                    setInputValue("");
                  }
                }
              }}
            />
          )}
          disableCloseOnSelect
        />
      </Grid>
    </Grid>
  );
}
